
import { defineComponent } from "vue";
import FieldServicesForm from "@/components/FieldServiceForm.vue";
import Footer from "@/components/Footer.vue";

export default defineComponent({
  components: {
    FieldServicesForm,
    Footer,
  },
});
